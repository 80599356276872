import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {EconApi, User} from '../shared/auth-services';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit {

  @Input() userId: any = null;
  @Output() contactsDataChange = new EventEmitter();

  constructor(private api: EconApi, private currentUser: User) {

    if (!this.userId) {
      this.userId = currentUser.info.id;
    }
  }

  contacts: any;

  ngOnInit() {
    this.getContactList();
  }

  getContactList() {
    this.api.getContactList(this.userId).then(data => {
      this.contacts = data;
      this.contactsDataChange.emit(this.contacts);
    });
  }

}
